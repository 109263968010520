<template>
  <div class="welfareHome">
    <!-- logo 搜索 company-->
    <search-com @search-btn="searchBtn" ref="searchCom"></search-com>
    <!-- 轮播图 导航栏 用户 -->
    <section class="welfare_top">
      <section class="page_center">
        <!-- 导航栏 -->
        <div class="gift_pack">
          <div style="position: relative; top: -5px; height: 5px; background: #ffbb00;"></div>
          <div class="holiday_package">
            <p class="navigation_title"><i></i>节日礼包</p>
            <p class="navigation_explain">(员工N选1)</p>
            <ul class="navigation_list">
              <li @click="goMealList">春节</li>
              <li @click="goMealList">女神节</li>
              <li @click="goMealList">端午节</li>
              <li @click="goMealList">中秋节</li>
            </ul>
          </div>
          <div class="corporate_benefits">
            <p class="navigation_title"><i></i>企业福利</p>
            <p class="navigation_explain">(集中配送、员工自领)</p>
            <ul class="navigation_list">
              <li
                v-for="(productItem, index) in productList"
                :key="index"
                @click="goListPage(productItem.id, productItem.name)"
              >
                {{ productItem.name }}
              </li>
              <li @click="goListPage(0, '全部')">更多>></li>
            </ul>
          </div>
        </div>
        <!-- 轮播banner -->
        <div class="carousel_box">
          <div
            v-if="bannerList.length > 1"
            class="banner_swiper swiper-container"
          >
            <div class="swiper-wrapper">
              <div
                v-for="item in bannerList"
                :key="item.id"
                class="swiper-slide mouse-style"
              >
                <img @click="goMealList" :src="item.bannerUrl" />
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <!-- <div class="swiper-pagination"></div> -->

            <!-- 如果需要导航按钮 -->
            <!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div> -->

            <!-- 如果需要滚动条 -->
            <!-- <div class="swiper-scrollbar"></div> -->
          </div>
        </div>
        <!-- 当前登录的企业信息 -->
        <div class="account_box">
          <div class="account_information">
            <div class="account_img">
              <img v-if="userData.userInfo" :src="userData.userInfo.userAvatarUrl" />
              <img class="pointer" v-else :src="defaultCompanyImg" @click="goStaging(0)"/>
            </div>
            <div class="account_name">
              <span v-if="userData.userInfo" class="name_text">{{userData.userInfo.companyName}}</span>
              <span v-else-if="userData.companyList&&userData.companyList.length==0" class="name_text pointer" @click="goStaging(0)">请创建企业账户</span>
              <span v-else class="name_text pointer" @click="goStaging(0)">请登录企业账户</span>
              <i v-if="userData.companyList&&userData.companyList.length > 1" class="change_company" @click="changeCompany"></i>
            </div>
          </div>
          <ul class="accountorder">
            <li>
              <p class="accountorder_num">{{userStatistics.orderToPayCount?userStatistics.orderToPayCount:0 }}</p>
              <p class="accountorder_text">待支付</p>
            </li>
            <li>
              <p class="accountorder_num">{{userStatistics.orderToReceiptCount? userStatistics.orderToReceiptCount:0 }}</p>
              <p class="accountorder_text">待收货</p>
            </li>
            <li>
              <p class="accountorder_num">{{userStatistics.commodityNoExchangeCount?userStatistics.commodityNoExchangeCount:0 }}</p>
              <p class="accountorder_text">待兑换</p>
            </li>
          </ul>
          <ul class="account_more">
            <li @click="goStaging(1)">
              <img src="../../assets/img/welfareMall/icon_order.png" /><span>我的订单</span><i class="el-icon-arrow-right"></i>
            </li>
            <li @click="goStaging(2)">
              <img src="../../assets/img/welfareMall/icon_shop.png" /><span>商品发放</span><i class="el-icon-arrow-right"></i>
            </li>
            <li @click="goStaging(3)">
              <img src="../../assets/img/welfareMall/icon_schedule.png" /><span>兑换进度</span><i class="el-icon-arrow-right"></i>
            </li>
          </ul>
        </div>
      </section>
    </section>
    <!-- 为你推荐 -->
    <section class="recommend_box page_center">
      <h2 class="home_title">
        <i class="icon_left"></i>为你推荐<i class="icon_right"></i>
      </h2>
      <div class="commodity_swiper swiper-container">
        <div class="swiper-wrapper">
          <div
            class="goodsList swiper-slide"
            v-for="(goodsItem, index) in recommendedContent"
            :key="index"
          >
            <div
              class="recommended_content"
              v-for="item in goodsItem"
              :key="item.id"
              @click="getGoodDetails(item)"
            >
              <img
                class="recommended_pictures pulse"
                :src="item.commodityPictures"
              />
              <p class="recommended_title">{{ item.commodityName }}</p>
              <span class="recommended_price">{{ item.commodityPrice?'￥'+item.commodityPrice:'暂无价格' }}</span>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <!-- 节日N选1礼包 -->
    <section class="holiday_gift page_center" v-if="festivalContent1.length">
      <h2 class="home_title"><i class="icon_left"></i>节日N选1礼包<i class="icon_right"></i></h2>
      <div class="festival_list">
        <a class="festival_item" :href=" jumpLink + '/welfare/TheSpringFestival.html'">春节</a>
        <a class="festival_item" :href=" jumpLink + '/welfare/WomensDay.html'">妇女节</a>
        <a class="festival_item" :href=" jumpLink + '/welfare/TheDragonBoatFestival.html'">端午节</a>
        <a class="festival_item" :href=" jumpLink + '/welfare/Mid-AutumnFestival.html'">中秋节</a>
      </div>
      <div class="gift_box">
        <a class="gift_img"><img src="~/assets/img/welfareMall/gift_img.png"></a>
         <div class="gift_list">
        <div class="gift_item" v-for="festivalItem in festivalContent1" :key="festivalItem.mealID" @click="goMealDetails(festivalItem)">
          <span class="gift_title">{{festivalItem.mealName}}</span>
          <img class="pulse" :src="festivalItem.mealUrl" :alt="festivalItem.mealName">
          <div class="gift_con">
              <div class="gift_price_box">
                  <p class="gift_price"><b class="price_text">{{festivalItem.mealPrice}}</b><span>￥</span></p>
                  <p class="gift_text">特惠价：</p>
              </div>
              <a rel="nofollow">查看更多</a>
          </div>
        </div>
      </div>
      </div>
      <div class="gift_list">
        <div class="gift_item" v-for="festivalItem in festivalContent2" :key="festivalItem.mealID" @click="goMealDetails(festivalItem)">
          <span class="gift_title">{{festivalItem.mealName}}</span>
          <img class="pulse" :src="festivalItem.mealUrl" :alt="festivalItem.mealName">
          <div class="gift_con">
              <div class="gift_price_box">
                  <p class="gift_price"><b class="price_text">{{festivalItem.mealPrice}}</b><span>￥</span></p>
                  <p class="gift_text">特惠价：</p>
              </div>
              <a rel="nofollow">查看更多</a>
          </div>
        </div>
      </div>
    </section>
    <!-- 企业福利 -->
    <section class="corporate_welfare page_center">
      <h2 class="home_title"><i class="icon_left"></i>企业福利<i class="icon_right"></i></h2>
      <div class="enterprise_content">
        <div class="enterprise_list" v-for="enterpriseList in enterpriseContent" :key="enterpriseList.id">
          <div class="enterprise_list_head">
            <span class="enterprise_head_title">{{ enterpriseList.name }}</span>
            <div class="enterprise_title_icon"></div>
            <span class="enterprise_more" @click="goListPage(enterpriseList.id, enterpriseList.name)">查看更多</span>
          </div>
          <div class="enterprise_item">
            <div 
              class="enterprise_item_content" v-for="enterpriseItem in enterpriseList.goodList" 
              :key="enterpriseItem.commodityID" @click="getGoodDetails(enterpriseItem)">
              <img class="enterprise_item_pic pulse" :src="enterpriseItem.commodityPictures" />
              <p class="enterprise_item_name">{{ enterpriseItem.commodityName }}</p>
              <span class="enterprise_item_price">￥{{ enterpriseItem.enterprisePrice }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 优惠券 品牌介绍 我们的优势 -->
    <section class="more_recommend page_center">
      <a :href="jumpLink+'/aboutus.html'">
        <img class="recommend_img1" src="~/assets/img/welfareMall/brand_introduction.png"  />
      </a>
      <img @click="goStaging(4)" class="recommend_img2" src="~/assets/img/welfareMall/collecting_vouchers.png" />
      <a :href="jumpLink+'/welfare.html'">
        <img class="recommend_img3" src="~/assets/img/welfareMall/our_advantages.png?v=20240307"  />
      </a>
    </section>
    <!-- 常见问题 -->
    <section class="common_problem page_center">
      <h2 class="home_title"><i class="icon_left"></i>常见问题<i class="icon_right"></i></h2>
      <a class="more_problem" :href="jumpLink + '/helpcenter.html'">更多 >></a>
      <div class="problem_list">
        <a
          v-for="(problemItem, i) in problemContent" :key="i"
          :href="jumpLink + '/helpcenter/details/' + problemItem.ArticleId + '.html'"
          class="problem_item"
          >
          <i class="problem_icon"></i>
          <span class="problem_title">{{ problemItem.ArticleName }}</span>
          <span class="review_count">{{ problemItem.ViewCount }}阅读</span>
        </a>
      </div>
    </section>

    <!-- 未检测到您账号的企业信息，请点击新增企业注册企业信息 -->
    <confirm-dialog
      :show.sync="noCompanyDialog.show"
      :title="noCompanyDialog.title"
      :dialog-width="noCompanyDialog.width"
      :confirm-text="noCompanyDialog.confirmText"
      :has-cancel-btn="noCompanyDialog.hasCancelBtn"
      @handle-confirm="newAddCompanyBtn"
      >
      <span slot="content">
        <div style="margin: 30px 0;text-align: left;">
          未检测到您账号的企业信息，请点击个人中心新增企业注册企业信息
        </div>
      </span>
    </confirm-dialog>
  </div>
</template>
<script>
import SearchCom from "@/components/welfareMallComp/SearchCom";
import ConfirmDialog from "@/components/publicCom/ConfirmDialog.vue";
import {
  getReqHomeBanner,
  getProductList,
  getWelfareShopList,
  getGoodList,
  getRecommendGoods,
  getProblem,
  getStatistics,
} from "network/welfareMall_api.js";
import { mapState } from "vuex";
export default {
  name: 'welfareHome',
  components: {
    SearchCom,
    ConfirmDialog
  },  
  data() {
    return {
      userData:{},
      productList: {}, //导航栏 企业福利类别
      bannerList: {}, //bannar列表
      defaultCompanyImg: require("../../assets/img/welfareMall/default_user.png"), //未登录显示头像
      userStatistics:{},
      recommendedContent: {}, //推荐商品/礼包展示
      festivalContent1: {}, //节日礼包1 
      festivalContent2: {}, //节日礼包2
      enterpriseContent: {}, //企业福利展示
      problemContent: {}, //常见问题
      noCompanyDialog: {//没企业时的弹窗提示
        title: '提示',
        show: false,
        width: '450px',
        hasCancelBtn: false
      },
      jumpLink: this.preUrlHome,
      jumpLoginLink:this.preUrlOld,
    };
  },
  computed: {
    // 映射
    ...mapState(["useInfo"]),
  },
  watch:{
    "userData": {
        handler: function (val) {
          console.log(this.userData)
          // 获取登录状态下的统计信息
          if(this.userData){
            this.getUserStatistics();
          }
          // 判断当前登录账号
          if(this.userData.userType == 'C'){
            console.log(this.userData.userType)
          }else if(this.userData.userType =='P'&& this.userData.companyList.length == 1){
            let userId = this.userData.companyList[0].userID;
            this.$refs.searchCom.reactLogin(userId);
          }else if(this.userData.userType =='P'&& this.userData.companyList.length>1){
            this.$refs.searchCom.switch_iconBtn();
          }else{
            this.$store.commit("openAddCompany");
          }
        }
    },
  },
  created() {
    this.getShopProductList();//获取导航栏 企业福利
    this.getBannerList();// 获取首页Banner    
    this.getRandomGoodList();//获取 为你推荐商品
    this.getWelShopList();//获取首页 节日礼包
    this.getWelGoodList();//获取首页 企业福利商品
    this.getProblemData();//获取常见问题
  },
  beforeUpdate(){
    this.loginUser(); 
  },
  methods: {
    loginUser(){
      if(this.useInfo&& this.useInfo.userType){
        this.userData= this.useInfo;
      }
    },
    //获取导航栏 企业福利
    async getShopProductList() {
      let _this = this;
      let res = await getProductList(7);
      if (res.isSuccess) {
        _this.productList = res.data;
        //console.log(_this.productList)
      }
    },

    // 获取首页Banner
    async getBannerList() {
      var _this = this;
      var res = await getReqHomeBanner();
      if (res.isSuccess) {
        _this.bannerList = res.data;
        // 动态获取数据之后 再初始化swiper 解决获取数据之后无滑动效果
        _this.$nextTick(function () {
          _this.bannerSwiper();
        });
      } else {
        _this.$message.error(res.msg);
      }
    },
    // 初始化 banner swiper
    bannerSwiper() {
      // 初始化swiper 配置 (3.X)
      new Swiper(".banner_swiper", {
        loop: true,
        // 如果需要分页器
        pagination: ".swiper-pagination",
        paginationClickable: true,
        // 如果需要前进后退按钮
        // nextButton: '.swiper-button-next',
        // prevButton: '.swiper-button-prev',
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
        //如果需要自动切换海报
        autoplay: 3000, //可选选项，自动滑动
        // autoplay: {
        //   delay: 1000,//时间 毫秒
        //   disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
        // },
      });
    },

    // 获取登录状态下的统计信息
    async getUserStatistics(){
      let res = await getStatistics();
      if(res.isSuccess){
        // console.log(res)
        this.userStatistics = res.data;
      }
    },

    //获取 为你推荐商品
    async getRandomGoodList() {
      let res = await getRecommendGoods();
      // console.log(res)
      if (res.isSuccess) {
        // console.log(res.data)
        // 只获取商品数据
        let content = res.data;
        //对图片进行处理
        content.forEach((item) => {
          item.commodityPictures = item.commodityPictures.split(";")[0];
        });
        let goodsData = [];
        let len = content.length;
        let num = len % 8 === 0 ? len / 8 : Math.floor(len / 8 + 1);
        for (let i = 0; i < num; i++) {
          const newArr = content.slice(i * 8, i * 8 + 8);
          goodsData.push(newArr);
        }
        this.recommendedContent = goodsData;
        this.$nextTick(function () {
          this.commoditySwiper();
        });
      }
    },
    // 初始化 商品 swiper
    commoditySwiper() {
      // 初始化swiper 配置 (3.X)
      new Swiper(".commodity_swiper", {
        loop: true,
        // 如果需要分页器
        pagination: ".swiper-pagination",
        paginationClickable: true,
        autoplay: 3000, //可选选项，自动滑动
      });
    },

    //获取首页 节日礼包
    async getWelShopList() {
      let res = await getWelfareShopList();
      if (res.isSuccess) {
        //console.log(res.data)
        this.festivalContent1 = res.data.slice(0,3);
        this.festivalContent2 = res.data.slice(3,7);
      }
    },

    //获取首页 企业福利商品
    async getWelGoodList() {
      let res = await getGoodList(4, 3);
      if (res.isSuccess) {
        //console.log(res.data)
        let content = res.data;
        //对图片进行处理
        content.forEach((item) => {
          let itemContent = item.goodList;
          itemContent.forEach((i) => {
            i.commodityPictures = i.commodityPictures.split(";")[0];
          });
          item.goodList = itemContent;
        });
        //console.log(content)
        this.enterpriseContent = content;
      }
    },

    //获取常见问题
    async getProblemData() {
      let res = await getProblem("节日福利");
      if (res.IsSuccess) {
        // console.log(res)
        this.problemContent = res.Data;
      }
    },

    // 导航栏跳转 礼包列表
    goMealList() {
      document.cookie = "vueRouter=" + "/welfareSetMealList";
      this.$router.push("/welfareSetMealList");
    },   
    // 导航栏跳转 类别列表页
    goListPage(id, name) {
      //console.log(id,name);
      sessionStorage.setItem("productList_categoryId", id);
      sessionStorage.setItem("productList_categoryName", name);
      document.cookie = "vueRouter=" + "/productList";
      this.$router.push({
        name: "productList",
        params: { id: id },
      });
    },

    //跳转 礼包详情页
    goMealDetails(info){
      const {mealID, isActive} = info;
      sessionStorage.setItem('welfareMall_mealId', mealID);
      // 判断该礼包是否有效
      sessionStorage.setItem('welfareMall_isActive', isActive);
      // sessionStorage.setItem('vueRouter', JSON.stringify('/welfareSetMealDetails'));
      document.cookie = "vueRouter="+ '/welfareSetMealDetails';
      let url = this.$router.resolve({
        name: 'WelfareSetMealDetails',
        params: {mealID} 
      }).href;
      window.open(url, '_blank');
    },
    // 跳转 商品详情
    getGoodDetails(item) {     
      if (item.commodityId) {
        item.commodityID = item.commodityId;
      }
      // console.log(item)
      const { commodityID } = item;
      //缓存当前ID 刷新用到
      sessionStorage.setItem("productList_commodityID", commodityID);
      document.cookie = "vueRouter=" + "/productDetails";
      let url = this.$router.resolve({
        name: "productDetails",
        params: { commodityID },
      }).href;
      window.open(url, '_blank')
    },
   
    // 点击查询 search
    searchBtn (val) {
      if(!val){
        return this.$message.info('请输入商品或礼包名称');
      }
      document.cookie = "vueRouter="+ '/welfareSearchResult';
      this.$router.push({
        name: 'WelfareSearchResult',
        params: {
          value: val
        }
      })
    },

    // 点击新增企业
    newAddCompanyBtn () {
      this.noCompanyDialog.show = false
    },
    //切换企业
    changeCompany(){
      //console.log('切换企业')
      this.$refs.searchCom.switch_iconBtn();
    },

    //跳转工作台对应页面
    goStaging(type){
      if(Object.keys(this.userData).length === 0){
        this.$bus.$emit('openLoginDialogCom');
        return;
      }
      const locationUrl=(type)=>{
        let url ={
          1:this.jumpLoginLink+'/HolidayWelfare/BillPay?official_ddgl', //订单管理
          2:this.jumpLoginLink+'/HolidayWelfare/MyPackage?official_spff', //商品发放
          3:this.jumpLoginLink+'/HolidayWelfare/CashProgress?official_ygdh',//员工兑换
          4:this.jumpLoginLink+'/Coupon/CouponsCenter?official_lqzx', //领券中心
        }
        return url[type]
      }
      if(this.userData.companyList.length ==0){
        this.$store.commit('openAddCompany');
      }else{
        location.href = locationUrl(type)  
      }
      // if(this.userData && this.userData.userType=='C'){
      //   location.href = locationUrl(type)
      // }else if(this.userData && this.userData.companyList ==0 ){
      //   this.$store.commit('openAddCompany');
      // }else{
      //   this.$bus.$emit('openLoginDialogCom')
      // }
    }
  },
};
</script>
<style lang="less" scoped>
.welfareHome{
  .page_center {
    width: 1200px;
    margin: 0 auto;
  }
  /* 标题 公共样式 */
  .home_title {
    font-size: 30px;
    font-weight: bold;
    color: #3c3c3c;
    line-height: 32px;
    text-align: center;
    i {
      display: inline-block;
      width: 31px;
      height: 20px;
    }
    .icon_left {
      margin-right: 25px;
      background: url(../../assets/img/welfareMall/icon_title1.png) no-repeat;
    }
    .icon_right {
      margin-left: 25px;
      background: url(../../assets/img/welfareMall/icon_title2.png) no-repeat;
    }
  }
  /* 页面顶部 导航 轮播 企业信息 */
  .welfare_top {
    width: 100%;
    height: 389px;
    background: url(../../assets/img/welfareMall/top_bg.png) no-repeat center;
    /* 导航栏 */
    .gift_pack {
      display: inline-block;
      width: 235px;
      height: 389px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
      .navigation_title {
        display: inline-flex;
        font-size: 18px;
        font-weight: bold;
        color: #071940;
        line-height: 22px;
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .navigation_explain {
        font-size: 14px;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 22px;
        margin-top: 6px;
      }
      .navigation_list {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        color: #3c3c3c;
        line-height: 22px;
        li:hover {
          cursor: pointer;
          color: #4c80fa;
        }
      }
      .holiday_package {
        margin-top: 32px;
        margin-left: 25px;
        .navigation_title i {
          background: url(../../assets/img/welfareMall/nav_jieri.png) no-repeat;
        }
        .navigation_list {
          li {
            display: inline-block;
            margin-bottom: 10px;
            margin-right: 35px;
          }
          li:nth-child(3) {
            margin-right: 0;
          }
        }
      }
      .corporate_benefits {
        margin-top: 27px;
        margin-left: 25px;
        .navigation_title i {
          background: url(../../assets/img/welfareMall/nav_qiye.png) no-repeat;
        }
        .navigation_list {
          li {
            display: inline-block;
            margin-bottom: 10px;
          }
          li:nth-child(2n-1) {
            margin-right: 50px;
          }
        }
      }
    }
    // 轮播图style
    .carousel_box {
      display: inline-block;
      width: 682px;
      height: 349px;
      position: relative;
      margin: 20px 20px 20px 25px;
      .swiper-container {
        width: 682px;
        height: 349px;
        border-radius: 10px;
        .swiper-wrapper {
          .swiper-slide {
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 500px;
            img {
              cursor: pointer;
              width: 100%;
              height: 100% !important;
            }
          }
        }
      }
    }
    // 当前登录的账户信息
    .account_box {
      float: right;
      display: inline-block;
      width: 236px;
      height: 349px;
      background: #ffffff;
      border-radius: 10px;
      margin-top: 20px;
      padding: 0 15px;
      .account_information {
        margin-top: 35px;
        text-align: center;
        .account_img {
          margin-bottom: 20px;
          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
          .pointer {
            cursor: pointer;
          }
        }
        .account_name {
          display: flex;
          align-items: center;
          justify-content: center;
          .name_text {
            font-size: 16px;
            font-weight: 400;
            color: #3c3c3c;
            line-height: 24px;
            
          }
          .change_company {
            width: 21px;
            height: 21px;
            margin-left: 3px;
            background: url(../../assets/img/welfareMall/icon_change.png) no-repeat;
            cursor: pointer;
          }
          .pointer {
            cursor: pointer;
          }
        }
      }
      .accountorder {
        margin-top: 20px;
        height: 65px;
        line-height: 65px;
        background: #fcfcfc;
        border-radius: 2px;
        li {
          display: inline-block;
          width: 33.3%;
          text-align: center;
          vertical-align: middle;
          .accountorder_num {
            font-size: 16px;
            font-weight: bold;
            color: #3c3c3c;
            line-height: 21px;
          }
          .accountorder_text {
            font-size: 14px;
            font-weight: 400;
            color: #9a9a9a;
            line-height: 21px;
            margin-top: 5px;
          }
        }
      }
      .account_more {
        margin-top: 10px;
        li {
          height: 30px;
          line-height: 30px;
          background: #fcfcfc;
          border-radius: 2px;
          margin-bottom: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            margin-left: 15px;
          }
          span {
            font-size: 12px;
            font-weight: 400;
            color: #3c3c3c;
            margin-left: 8px;
          }
          i {
            margin-left: 101px;
          }
        }
      }
    }
  }
  // 为你推荐
  .recommend_box {
    margin-top: 80px;
    .commodity_swiper {
      margin-top: 70px;
      padding-bottom: 55px;
      .recommended_content {
        display: inline-block;
        width: 270px;
        margin-left: 40px;
        margin-bottom: 40px;
        cursor: pointer;
        .recommended_pictures {
          width: 270px;
          height: 270px;
          border-radius: 10px;
        }
        .recommended_title {
          font-size: 20px;
          font-weight: 400;
          color: #3c3c3c;
          line-height: 20px;
          margin-top: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .recommended_price {
          display: block;
          font-size: 24px;
          font-weight: bold;
          color: #e8655b;
          line-height: 24px;
          margin-top: 20px;
        }
      }
      .recommended_content:nth-child(4n-3) {
        margin-left: 0;
      }
      .swiper-pagination {
        bottom: 40px;
        /deep/.swiper-pagination-bullet-active {
          width: 24px;
          border-radius: 4px;
        }
      }
    }
  }
  // 节日N选1礼包
  .holiday_gift {
    margin-top: 25px;
    .festival_list{
      margin-top: 70px;
      text-align: center;
      .festival_item{
        display: inline-block;
        width: 187px;
        height: 60px;
        background: #F9F9F9;
        margin-left: 84px;
        font-size: 24px;
        font-weight: 400;
        color: #3C3C3C;
        line-height: 60px;
        text-align: center;
        border-radius: 30px;
      }
      .festival_item:nth-child(1){
        margin-left: 0;
      }
      .festival_item:hover{
        color: #fff;
        background: #4C80FA;
      }
    }
    .gift_box{
      display: inline-flex;
      margin-top: 70px;
      .gift_img{
        width: 285px;
        height: 375px;
      }
      .gift_list{        
        margin-top: 0 !important;
        margin-left: 20px;
      }
    }
    .gift_list{
      display: inline-flex;
      margin-top: 30px;
      .gift_item{
        display: inline-block;
        width: 285px;
        height: 375px;
        background-image: url(../../assets/img/welfareMall/giftbox_bg.png);
        background-size: cover;
        .gift_title{
          float: right;
          width: 132px;
          height: 38px;
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 38px;
          text-align: center;
        }
        img{
          display: block;
          width: 232px;
          height: 176px;
          margin: 70px auto;
        }
        .gift_con{
          display: flex;
          align-items: center;
          .gift_price_box{
            display: inline-block;
            width: 118px;
            text-align: center;
            p.gift_price{
              width: 100px;
              font-size: 28px;
              font-weight: 400;
              color: #EC5434;
              line-height: 30px;
              margin:0 auto;
              .price_text{
                display: inline-block;
                width: 70px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              span{
                display: inline-block;
                width: 15px;
                height: 15px;
                line-height: 15px;
                background: #EC5434;
                border-radius: 50%;
                font-size: 12px;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                margin-left: 2px;
              }
            }
            p.gift_text{
              display: block;
              width: 73px;
              height: 16px;
              margin: 0 auto;
              background: #8FDE06;
              border-radius: 8px;
              font-size: 12px;
              font-weight: 400;
              color: #112945;
            }
          }
          a{
            display: inline-block;
            width: 119px;
            height: 35px;
            background: #FE9000;
            border-radius: 18px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 35px;
            text-align: center;
            margin-left: 23px;
          }
        }
      }
      .gift_item:nth-child(n+2){
        margin-left: 20px;
      }
      .gift_item:hover{
        cursor: pointer;
        transform: translateY(-10px);
      }
    }
  }
  // 企业福利展示
  .corporate_welfare {
    padding-top: 60px;
    .enterprise_content {
      margin-top: 60px;
      .enterprise_list {
        display: inline-block;
        width: 580px;
        height: 358px;
        border-radius: 20px;
        margin-right: 40px;
        margin-bottom: 30px;
        .enterprise_list_head {
          height: 94px;
          line-height: 94px;
          .enterprise_head_title {
            display: inline-block;
            width: 100px;
            font-size: 20px;
            font-weight: bold;
            color: #3c3c3c;
            line-height: 20px;
            margin-top: 30px;
            margin-left: 30px;
            position: relative;
            z-index: 2;
          }
          .enterprise_title_icon {
            width: 48px;
            height: 10px;
            position: absolute;
            z-index: 1;
            margin-top: -45px;
            margin-left: 30px;
          }
          .enterprise_more {
            display: inline-block;
            width: 100px;
            font-size: 18px;
            font-weight: 400;
            color: #3c3c3c;
            line-height: 24px;
            margin-left: 318px;
          }
          .enterprise_more:hover {
            cursor: pointer;
          }
        }
        .enterprise_item {
          padding: 0 30px;
          .enterprise_item_content {
            display: inline-block;
            width: 160px;
            margin-right: 20px;
            cursor: pointer;
            .enterprise_item_pic {
              width: 160px;
              height: 160px;
              border-radius: 10px;
            }
            .enterprise_item_name {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 12px;
            }
            .enterprise_item_price {
              display: inline-block;
              width: 50px;
              font-size: 16px;
              font-weight: bold;
              color: #f5222d;
              line-height: 20px;
              margin-top: 10px;
            }
          }
          .enterprise_item_content::after {
            position: absolute;
            text-align: center;
            width: 18px;
            height: 18px;
            margin-top: 10px;
            margin-left: 70px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 32px;
            content: "";
            background: url("../../assets/img/welfareMall/add_cart.png") no-repeat
              center;
          }
          .enterprise_item_content:last-child {
            margin-right: 0;
          }
        }
      }
      .enterprise_list:nth-child(1) {
        background: linear-gradient(180deg, #fff8eb 0%, #ffffff 100%);
        .enterprise_title_icon {
          background: linear-gradient(90deg, #fac04c 0%, #fff9ec 100%);
        }
        .enterprise_more {
          background: url(../../assets/img/welfareMall/corporate_icon1.png) no-repeat right center;
        }
        .enterprise_more:hover {
          color: #FFB656;
        }
      }
      .enterprise_list:nth-child(2) {
        background: linear-gradient(180deg, #f5fff5 0%, #ffffff 100%);
        .enterprise_title_icon {
          background: linear-gradient(90deg, #86d222 0%, #f9fdeb 100%);
        }
        .enterprise_more {
          background: url(../../assets/img/welfareMall/corporate_icon2.png) no-repeat right center;
        }
        .enterprise_more:hover {
          color: #3ACF9A;
        }
      }
      .enterprise_list:nth-child(3) {
        background: linear-gradient(180deg, #fdffeb 0%, #ffffff 100%);
        .enterprise_title_icon {
          background: linear-gradient(90deg, #fae34c 0%, #fff9ec 100%);
        }
        .enterprise_more {
          background: url(../../assets/img/welfareMall/corporate_icon3.png) no-repeat right center;
        }
        .enterprise_more:hover {
          color: #FFDC00;
        }
      }
      .enterprise_list:nth-child(4) {
        background: linear-gradient(180deg, #fff5f5 0%, #ffffff 100%);
        .enterprise_title_icon {
          background: linear-gradient(90deg, #ff8181 0%, #fdf2eb 100%);
        }
        .enterprise_more {
          background: url(../../assets/img/welfareMall/corporate_icon4.png) no-repeat right center;
        }
        .enterprise_more:hover {
          color: #FF8584;
        }
      }
      .enterprise_list:nth-child(5) {
        background: linear-gradient(180deg, #ebf6ff 0%, #ffffff 100%);
        .enterprise_title_icon {
          background: linear-gradient(90deg, #87c9ff 0%, #ecfeff 100%);
        }
        .enterprise_more {
          background: url(../../assets/img/welfareMall/corporate_icon5.png) no-repeat right center;
        }
        .enterprise_more:hover {
          color: #3AA7FF;
        }
      }
      .enterprise_list:nth-child(6) {
        background: linear-gradient(180deg, #f5f6ff 0%, #ffffff 100%);
        .enterprise_title_icon {
          background: linear-gradient(90deg, #98a2ff 0%, #edebfd 100%);
        }
        .enterprise_more {
          background: url(../../assets/img/welfareMall/corporate_icon6.png) no-repeat right center;
        }
        .enterprise_more:hover {
          color: #9D5EF2;
        }
      }
      .enterprise_list:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  // 品牌介绍 领券中心 我们的优势
  .more_recommend {
    margin-top: 50px;
    img:hover {
      cursor: pointer;
      transform: translateY(-5px);
    }
    .recommend_img2 {
      margin: 0 19px;
      background-size: cover;
    }
    .recommend_img3{
      height:165px ;
      background-size: cover;
    }
  }
  // 常见问题
  .common_problem {
    margin-top: 80px;
    margin-bottom: 64px;
    .more_problem {
      position: relative;
      float: right;
      font-size: 14px;
      font-weight: 400;
      color: #071940;
      line-height: 18px;
      margin-top: -18px;
    }
    .more_problem:hover {
      color: #4c80fa;
    }
    .problem_list {
      margin-top: 70px;
      .problem_item {
        display: inline-block;
        width: 560px;
        margin-bottom: 16px;
        .problem_icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          background-image: url(../../assets/img/welfareMall/problem_icon.png);
          background-size: cover;
        }
        .problem_title {
          display: inline-block;
          width: 425px;
          font-size: 16px;
          color: #3c3c3c;
          line-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 15px;
        }
        .problem_title:hover {
          color: #4c80fa;
        }
        .review_count {
          display: inline-block;
          float: right;
          width: 70px;
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          line-height: 40px;
          color: #9a9a9a;
        }
      }
      .problem_item:nth-child(2n) {
        margin-left: 75px;
      }
    }
  }
}
</style>